import { loadingEnd, loadingStart } from "./root.actions";

/* 
1> Update store with user action data on Special product (Sync)
2> Update the backend with user action Special product (Async)
3> Get the latest special data on page load. (Async)

*/
export const specialCartActionTypes = {
  FETCH_SPECIAL_CART_DATA_SUCCESS: "FETCH_SPECIAL_CART_DATA_SUCCESS",
  FETCH_SPECIAL_CART_DATA_FAILURE: "FETCH_SPECIAL_CART_DATA_FAILURE",
};

const fetchSpecialCartDataSuccessAction = (data) => {
  return {
    type: specialCartActionTypes.FETCH_SPECIAL_CART_DATA_SUCCESS,
    data,
  };
};

// export const increaseSpecialCartDataAction = (productInfo) => (dispatch, getState) => {
//   dispatch(loadingStart());
//   const latestState = getState();
//   const cartItemData = latestState.specialCart.items.find(
//     (item) => item.id === productInfo.id
//   );
//   if (!cartItemData) {
//     productInfo.count = parseInt(productInfo.count) + 1;
//     latestState.cart.items.push(productInfo);
//   } else {
//     cartItemData.count++;
//   }
//   dispatch(fetchSpecialCartDataSuccessAction(latestState.specialCart.items));
//   dispatch(loadingEnd());
// };

// export const deleteCartDataAction = (id) => (dispatch, getState) => {
//   dispatch(loadingStart());
//   let latestState = getState();
//   let updatedProduct = latestState.cart.items.filter(
//     (product) => product.id !== id
//   );
//   if (updatedProduct.length === 0) {
//     let productDetails = latestState.product.items.find(
//       (product) => product.id === id
//     );
//     productDetails.count = 0;

//     latestState.cart.items = [];
//     updatedProduct = [];
//   }
//   dispatch(fetchSpecialCartDataSuccessAction(updatedProduct));
//   dispatch(loadingEnd());
// };

export const clearCartDataAction = () => (dispatch, getState) => {
  dispatch(loadingStart());
  let latestState = getState();
  latestState.cart.items = [];
  let cartUpdated = latestState.cart.items;
  // latestState.product.items.map((item) => {
  //   if (item.count > 0) {
  //     item.count = 0;
  //   }
  //   return item;
  // });
  dispatch(fetchSpecialCartDataSuccessAction(cartUpdated));
  dispatch(loadingEnd());
};

// export const decreaseCartDataAction = (productInfo) => (dispatch, getState) => {
//   dispatch(loadingStart());
//   let latestState = getState();
//   let cartItem = latestState.cart.items.find(
//     (item) => item.id === productInfo.id
//   );
//   if (cartItem) {
//     cartItem.count--;
//   }

//   if (cartItem && cartItem.count === 0) {
//     // remove cartItem.ProductId from cart
//     latestState.cart.items = latestState.cart.items.filter(
//       (item) => item.id !== productInfo.id
//     );
//   }
//   dispatch(fetchSpecialCartDataSuccessAction(latestState.cart.items));
//   dispatch(loadingEnd());
// };

export const updateSpecialCartInputDataAction =
  (productInfo, value) => (dispatch, getState) => {
    dispatch(loadingStart());
    const latestState = getState();
    // TODO: Update productID to id in entire action
    const specialCart = latestState.specialCart.items.find(
      (item) => item.id === productInfo.id
    );

    if (!specialCart) {
      if (value > 0) {
        // value = 0 ? parseInt(value) : value;
        productInfo.count = parseInt(value);
        latestState.specialCart.items.push(productInfo);
      }
    } else {
      value = 0 ? parseInt(value) : value;
      productInfo.count = parseInt(value);
      // latestState.cart.items.push(productInfo);
      specialCart.count = parseInt(value);
    }
    dispatch(fetchSpecialCartDataSuccessAction(latestState.specialCart.items));
    dispatch(loadingEnd());
  };

// export const fetchCartDataAction = () => (dispatch) => {
//   dispatch(loadingStart());

//   // TODO: need to be changed to actual API call implementation

//   // setTimeout is just for demonstration purposes
//   setTimeout(() => {
//     dispatch(loadingEnd());
//     // dispatch(fetchSpecialCartDataSuccessAction(cartData));
//   }, 500);
// };

export const moveSpecialCartItemsToCartAction =
  (orderTypeCart) => (dispatch, getState) => {
    const latestState = getState();
    const cartItemsCopy = latestState.cartItems.items;
    let cartArray = [];
    if (orderTypeCart !== "ZOR") {
      cartItemsCopy &&
        cartItemsCopy.map((cartItem) => {
          cartArray.push(cartItem);
          return cartArray;
        });
    }
    dispatch(fetchSpecialCartDataSuccessAction(cartArray));
  };
