import { cartEngagementActionTypes } from "../actions/cartEngagement.actions";

export default function cartEngagementReducer(state = null, action) {
  switch (action.type) {
    case cartEngagementActionTypes.SET_CART_ENGAGEMENT_DATA:
      return {
        ...state,
        [action.key]: { ...state[action.key], ...action.data },
      };
    case cartEngagementActionTypes.SET_CHECKOUT_TYPE:
      return {
        ...state,
        checkoutType: action.data,
      };
    case cartEngagementActionTypes.SET_CART_IS_FETCHED:
      return {
        ...state,
        isFetched: action.payload,
      };
    case cartEngagementActionTypes.SET_CART_CLEAR:
      return {
        REWARDS: null,
        ZOR: null,
        checkoutType: null,
        isFetched: false,
      };
    default:
      return state;
  }
}
