export const modalsActionTypes = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
};

export const toggleModalAction = (key, data = false) => {
  return {
    type: modalsActionTypes.TOGGLE_MODAL,
    key,
    data,
  };
};
