export const getEnv = () => {
  let languageEnv;
  if (localStorage.getItem("language")) {
    languageEnv = localStorage.getItem("language");
  } else {
    localStorage.setItem("language", process.env.REACT_APP_LANGUAGE_LOCAL);
    languageEnv = process.env.REACT_APP_LANGUAGE_LOCAL;
  }
  if (process.env.REACT_APP_HOST_CN_DEV === window.location.hostname) {
    // CN env details
    return {
      host: process.env.REACT_APP_HOST_CN_DEV,
      url: process.env.REACT_APP_BASE_URL_CN_DEV,
      siteId: process.env.REACT_APP_SITE_ID_CN,
      xApiKey: process.env.REACT_APP_XAPIKEY_CN_DEV,
      currency: process.env.REACT_APP_CURRENCY_CN,
      language: languageEnv,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CN,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,
    };
  } else if (process.env.REACT_APP_HOST_CN_PROD === window.location.hostname) {
    // CN PROD env details
    return {
      host: process.env.REACT_APP_HOST_CN_DEV,
      url: process.env.REACT_APP_BASE_URL_CN_PROD,
      siteId: process.env.REACT_APP_SITE_ID_CN,
      xApiKey: process.env.REACT_APP_XAPIKEY_CN_PROD,
      currency: process.env.REACT_APP_CURRENCY_CN,
      language: languageEnv,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CN,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CN,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CN,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CN,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CN,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CN,
    };
  } else if (process.env.REACT_APP_HOST_CEP_DEV === window.location.hostname) {
    // CEP env details
    return {
      host: process.env.REACT_APP_HOST_CEP_DEV,
      url:
        process.env.REACT_APP_CEP_DEV_OKTA_ENABLED === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_CEP_DEV
          : process.env.REACT_APP_BASE_URL_CEP_DEV,
      siteId: process.env.REACT_APP_SITE_ID_CEP,
      xApiKey: process.env.REACT_APP_XAPIKEY_CEP_DEV,
      currency: process.env.REACT_APP_CURRENCY_CEP,
      language: process.env.REACT_APP_LANGUAGE_CEP,
      displayName: process.env.REACT_APP_DISPLAY_NAME_CEP,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_CEP,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_CEP,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_CEP,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_CEP,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_CEP,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_CEP_DEV_OKTA_ENABLED,
      loyaltyEnabled: process.env.REACT_APP_CEP_DEV_LOYALTY_ENABLED === "true",
    };
  } else if (process.env.REACT_APP_HOST_AU_DEV === window.location.hostname) {
    // AU env details
    return {
      host: process.env.REACT_APP_HOST_AU_DEV,
      url:
        process.env.REACT_APP_OKTA_ENABLED_AU === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_AU_DEV
          : process.env.REACT_APP_BASE_URL_AU_DEV,
      siteId: process.env.REACT_APP_SITE_ID_AU,
      xApiKey: process.env.REACT_APP_XAPIKEY_AU_DEV,
      currency: process.env.REACT_APP_CURRENCY_AU,
      language: process.env.REACT_APP_LANGUAGE_AU,
      displayName: process.env.REACT_APP_DISPLAY_NAME_AU,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_AU,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_AU,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_AU,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_AU,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_AU,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_OKTA_ENABLED_AU,
      loyaltyEnabled: process.env.REACT_APP_LOYALTY_ENABLED_AU === "true",
    };
  } else if (process.env.REACT_APP_HOST_AU_PROD === window.location.hostname) {
    // AU PROD env details
    return {
      host: process.env.REACT_APP_HOST_AU_PROD,
      url: process.env.REACT_APP_BASE_URL_AU_PROD,
      siteId: process.env.REACT_APP_SITE_ID_AU,
      xApiKey: process.env.REACT_APP_XAPIKEY_AU_PROD,
      currency: process.env.REACT_APP_CURRENCY_AU,
      language: process.env.REACT_APP_LANGUAGE_AU,
      displayName: process.env.REACT_APP_DISPLAY_NAME_AU,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_AU,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_AU,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_AU,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_AU,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_AU,
    };
  } else if (process.env.REACT_APP_HOST_NZ_DEV === window.location.hostname) {
    // NZ env details
    return {
      host: process.env.REACT_APP_HOST_NZ_DEV,
      url:
        process.env.REACT_APP_OKTA_ENABLED_NZ === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_NZ_DEV
          : process.env.REACT_APP_BASE_URL_NZ_DEV,
      siteId: process.env.REACT_APP_SITE_ID_NZ,
      xApiKey: process.env.REACT_APP_XAPIKEY_NZ_DEV,
      currency: process.env.REACT_APP_CURRENCY_NZ,
      language: process.env.REACT_APP_LANGUAGE_NZ,
      displayName: process.env.REACT_APP_DISPLAY_NAME_NZ,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_NZ,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_NZ,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_NZ,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_NZ,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_NZ,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_OKTA_ENABLED_NZ,
      loyaltyEnabled: process.env.REACT_APP_LOYALTY_ENABLED_NZ === "true",
    };
  } else if (process.env.REACT_APP_HOST_NZ_PROD === window.location.hostname) {
    // NZ PROD env details
    return {
      host: process.env.REACT_APP_HOST_NZ_PROD,
      url:
        process.env.REACT_APP_OKTA_ENABLED_NZ === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_NZ_DEV
          : process.env.REACT_APP_BASE_URL_NZ_PROD,
      siteId: process.env.REACT_APP_SITE_ID_NZ,
      xApiKey: process.env.REACT_APP_XAPIKEY_NZ_PROD,
      currency: process.env.REACT_APP_CURRENCY_NZ,
      language: process.env.REACT_APP_LANGUAGE_NZ,
      displayName: process.env.REACT_APP_DISPLAY_NAME_NZ,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_NZ,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_NZ,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_NZ,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_NZ,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_NZ,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_OKTA_ENABLED_NZ,
      loyaltyEnabled: process.env.REACT_APP_LOYALTY_ENABLED_NZ === "true",
    };
  } else {
    return {
      host: process.env.REACT_APP_HOST_NZ_DEV,
      url:
        process.env.REACT_APP_OKTA_ENABLED_NZ === "true"
          ? process.env.REACT_APP_BASE_URL_OKTA_NZ_DEV
          : process.env.REACT_APP_BASE_URL_NZ_DEV,
      siteId: process.env.REACT_APP_SITE_ID_NZ,
      xApiKey: process.env.REACT_APP_XAPIKEY_NZ_DEV,
      currency: process.env.REACT_APP_CURRENCY_NZ,
      language: process.env.REACT_APP_LANGUAGE_NZ,
      displayName: process.env.REACT_APP_DISPLAY_NAME_NZ,
      apOpenId: process.env.REACT_APP_OPENID,
      layoutWithImage: process.env.REACT_APP_LAYOUT_WITH_IMAGE_NZ,
      displayGridView: process.env.REACT_APP_DISPLAY_GRID_NZ,
      displayBarCode: process.env.REACT_APP_DISPLAY_BARCODE_NZ,
      displayWishlist: process.env.REACT_APP_DISPLAY_WISHLIST_NZ,
      displayUnitType: process.env.REACT_APP_DISPLAY_UNIT_TYPE_NZ,
      oktaUrl: process.env.REACT_APP_OKTA_URL,
      oktaIssuer: process.env.REACT_APP_OKTA_ISSUER,
      oktaClientId: process.env.REACT_APP_OKTA_CLIENTID,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      oktaLogin: process.env.REACT_APP_OKTA_ENABLED_NZ,
      loyaltyEnabled: process.env.REACT_APP_LOYALTY_ENABLED_NZ === "true",
    };
  }
};

// TODO: need to remove loyaltyEnabled condition once single endpoint instance will create.
export const getTranslations = getEnv().loyaltyEnabled
  ? `${getEnv().url}/translation`
  : `${getEnv().url}/translations`;
export const accessTokenAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/auth/login`
  : `${getEnv().url}/accessToken`;
export const changePasswordUrl = `${getEnv().url}/changePassword`;
export const checkSupervisorUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/account/supervisor`
  : `${getEnv().url}/checkSupervisor`;
export const validateTokenAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/token/validate`
  : `${getEnv().url}/validateToken`;
export const forgotPasswordAPIUrl = `${getEnv().url}/forgotPassword`;
export const homeAPIUrl = `${getEnv().url}/home`;
export const getNavigationsAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/navigation`
  : `${getEnv().url}/navigations`;
export const productListAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/product`
  : `${getEnv().url}/productList`;
export const specialProductListAPIUrl = `${getEnv().url}/specialProductList`;
export const getAccountDetailsAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/account`
  : `${getEnv().url}/myAccount`;

export const productBrandsAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/product/brand`
  : `${getEnv().url}/productBrands`;
export const productSubBrandsAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/brands/sub`
  : `${getEnv().url}/productSubBrands`;
export const productAddToWishListAPIUrl = `${getEnv().url}/addwishlistitem`;
export const productRemoveToWishListAPIUrl = `${
  getEnv().url
}/removewishlistitem`;

export const getOrderHistoryAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/order`
  : `${getEnv().url}/orderHistory`;
export const getOrderDetailsAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/order`
  : `${getEnv().url}/orderDetails`;
export const getCustomerDetailsAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/account`
  : `${getEnv().url}/customerDetails`;
export const getMyAccountUrl = `${getEnv().url}/myAccount`;

export const getHelpInfoUrl = `${getEnv().url}/getHelpInfo`;
export const getContactUsInfoUrl = `${getEnv().url}/getContactUsInfo`;

export const getTermsDetailsAPIUrl = `${getEnv().url}/getCheckoutCondition`;
export const createOrderAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/order`
  : `${getEnv().url}/createOrder`;
// export const addCartAPIUrl = `${url}/addCart`;
export const getCartAPIUrl = `${getEnv().url}/getCart`;
export const updateCartAPIUrl = `${getEnv().url}/updateCart`;
export const clearCartAPIUrl = `${getEnv().url}/clearCart`;
export const cancelOrderAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/order`
  : `${getEnv().url}/cancelOrder`;
export const deleteOrderAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/order`
  : `${getEnv().url}/deleteOrder`;
export const orderInfoAPIUrl = `${getEnv().url}/getOrderSKUDetails`;
export const creditAmountAPIUrl = `${getEnv().url}/creditAmount`;
export const statementOfAccountAPIUrl = `${getEnv().url}/statementOfAccounts`;
export const returnMaterialReasonCodeAPIUrl = `${
  getEnv().url
}/returnReasonCodes`;
export const createReturnOrderAPIUrl = `${getEnv().url}/createReturnOrder`;
export const downloadPDFReturnOrderAPIUrl = `${
  getEnv().url
}/downloadInvoicePDF`;
export const addReturnOrderImagesAPIUrl = `${
  getEnv().url
}/addReturnOrderImages`;
export const getWishListAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/wishlist`
  : `${getEnv().url}/getwishlist`;
export const clearWishListAPIUrl = `${getEnv().url}/clearwishlist`;
export const orderReportAPIUrl = `${getEnv().url}/reports/orderReport`;
export const getSuggestedOrderAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/orders/suggested`
  : `${getEnv().url}/suggestedOrder`;
export const getSuggestedOrderDetailAPIUrl = getEnv().loyaltyEnabled
  ? `${getEnv().url}/orders/suggested`
  : `${getEnv().url}/suggestedOrderDetail`;
export const getCustomerArchiveStatusAPIUrl = `${
  getEnv().url
}/getCustomerArchiveStatus`;
export const getContentAPIUrl = `${getEnv().url}/content`;
export const getRewardsAPIUrl = `${getEnv().url}/rewards`;
export const getCartEngagementUrl = `${getEnv().url}/cart`;
export const activityUrl = `${getEnv().url}/activity`;

// API Header with Site Id
export const apiHeaderWithSiteId = {
  "X-Api-Key": getEnv().xApiKey,
  "Content-Type": "application/json",
  // "Content-Language": process.env.REACT_APP_LANGUAGE,
  "Content-Language": getEnv().language,
  SiteId: getEnv().siteId,
};

// OKTA Flag
export const isOktaLogin = getEnv().oktaLogin === "true";
