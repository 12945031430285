import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import SessionTimeout from "./SessionTimeout";
import { allHeaderData } from "./store/selectors/navItems.selector";
import theme from "./theme";
import Routes from "./routes/Routes";

function App() {
  const headerData = useSelector(allHeaderData);
  const isAuthenticated = headerData.isAuth;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SessionTimeout isAuthenticated={isAuthenticated} />
        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
