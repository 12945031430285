import { isOktaLogin } from "./urls";

const { fetch: originalFetch } = window;

const getAuthHeaders = (state) => {
  return {
    token: state?.navItems?.token || "",
    isAuth: state?.navItems?.isAuth || "",
  };
};

export default {
  setup: (store) => {
    window.fetch = async (...args) => {
        try {
            let [resource, config] = args;
            // request interceptor here
            let { isAuth, token } = getAuthHeaders(store.getState());
            if (config?.headers && isAuth && token) {
              if (!isOktaLogin) {
                config.headers.Authorization = `Bearer ${token}`;
              }
              if (isOktaLogin) {
                config.headers["okta-accesstoken"] = token;
              }
            }
            let response = await originalFetch(resource, config);
            // response interceptor here
            if (!response.ok) {
              // error handling
              return Promise.reject(response);
            }
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    };
  },
};
