import { getMethod, postMethod, putMethod } from "../../utils/api";
import {
  countPointsAndTotalRewards,
  createAddCartData,
  ORDER_TYPES,
} from "../../utils/common";
import {
  clearCartAPIUrl,
  getCartAPIUrl,
  getCartEngagementUrl,
  getEnv,
  updateCartAPIUrl,
} from "../../utils/urls";
import {
  checkoutTypeSelector,
  productsCartEngagementSelector,
  rewardsCartEngagementSelector,
} from "../selectors/cartEngagement.selector";
export const cartEngagementActionTypes = {
  SET_CART_ENGAGEMENT_DATA: "SET_CART_ENGAGEMENT_DATA",
  SET_CHECKOUT_TYPE: "SET_CHECKOUT_TYPE",
  SET_CART_IS_FETCHED: "SET_CART_IS_FETCHED",
  SET_CART_CLEAR: "SET_CART_CLEAR",
};

export const setCartEngagementDataAction = (data, key) => {
  return {
    type: cartEngagementActionTypes.SET_CART_ENGAGEMENT_DATA,
    key,
    data,
  };
};

export const setCheckoutTypeAction = (data) => {
  return {
    type: cartEngagementActionTypes.SET_CHECKOUT_TYPE,
    data,
  };
};

export const setCartIsFetchedAction = (data) => {
  return {
    type: cartEngagementActionTypes.SET_CART_IS_FETCHED,
    payload: data,
  };
};
export const setCartClearAction = () => {
  return {
    type: cartEngagementActionTypes.SET_CART_CLEAR,
  };
};

const changeCheckoutType = (
  rewardsCart,
  productsCart,
  data,
  orderType,
  dispatch
) => {
  if (
    orderType === ORDER_TYPES.ZOR &&
    !data?.items?.length &&
    rewardsCart?.items?.length
  ) {
    return dispatch(setCheckoutTypeAction(ORDER_TYPES.REWARDS));
  }
  if (
    orderType === ORDER_TYPES.REWARDS &&
    !data?.items?.length &&
    productsCart?.items?.length
  ) {
    return dispatch(setCheckoutTypeAction(ORDER_TYPES.ZOR));
  }
  return dispatch(setCheckoutTypeAction(orderType));
};

export const getCartEngagement = (orderType = "ZOR") => async (
  dispatch,
  getState
) => {
  try {
    if (orderType) {
      let url = getEnv().loyaltyEnabled
        ? `${getCartEngagementUrl}?orderType=${orderType}`
        : getCartAPIUrl;
      let data = await getMethod(url);
      if (data?.success === false) {
        // TODO: show error notification
        return data;
      }
      let total = countPointsAndTotalRewards(data.items || []);
      dispatch(setCartEngagementDataAction({ ...data, ...total }, orderType));
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addToCartEngagement = (orderType, item) => async (
  dispatch,
  getState
) => {
  try {
    let quantity = 1;
    let rewardCart = rewardsCartEngagementSelector(getState());
    if (orderType === ORDER_TYPES.REWARDS) {
      let thisItem = rewardCart?.items?.find(
        (product) => product.id === item.id
      );
      if (thisItem) {
        quantity = parseInt(thisItem.count) + 1;
      }
    }
    let body = createAddCartData(orderType, item, quantity, rewardCart?.items);
    let data = await putMethod(getCartEngagementUrl, body);
    if (data?.success === false) {
      console.log("Error: ", data);
      // TODO: show error notification
      return data;
    }
    let total = countPointsAndTotalRewards(data.items || []);
    dispatch(
      setCartEngagementDataAction({ ...data, ...total }, data.orderType)
    );
    dispatch(setCheckoutTypeAction(orderType));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateCartEngagement = (orderType, item, qty) => async (
  dispatch,
  getState
) => {
  try {
    let loyaltyEnabled = getEnv().loyaltyEnabled;
    let cart = { items: [] };
    let rewardsCart = rewardsCartEngagementSelector(getState());
    let productsCart = productsCartEngagementSelector(getState());
    if (orderType === ORDER_TYPES.REWARDS) {
      cart = rewardsCart;
    }
    if (orderType === ORDER_TYPES.ZOR) {
      cart = JSON.parse(JSON.stringify(productsCart));
      cart.items = cart?.items?.map((item) => {
        item.unitType =
          item?.unitType?.find((unit) => unit.active === 1)?.key || "CS";
        return item;
      });
    }
    let body = createAddCartData(orderType, item, qty, cart?.items);
    let url = loyaltyEnabled ? getCartEngagementUrl : updateCartAPIUrl;
    let data = await putMethod(url, body);
    if (data?.success === false) {
      console.log("Error: ", data);
      // TODO: show error notification
      return data;
    }
    let total = countPointsAndTotalRewards(data.items || []);
    dispatch(
      setCartEngagementDataAction({ ...data, ...total }, data.orderType)
    );
    if (loyaltyEnabled) {
      changeCheckoutType(rewardsCart, productsCart, data, orderType, dispatch);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const putCartDataEngagement = (body) => async (dispatch, getState) => {
  try {
    let loyaltyEnabled = getEnv().loyaltyEnabled;
    let url = loyaltyEnabled ? getCartEngagementUrl : updateCartAPIUrl;
    let data = await putMethod(url, body);
    if (data?.success === false) {
      console.log("Error: ", data);
      // TODO: show error notification
      return data;
    }
    let total = countPointsAndTotalRewards(data.items || []);
    dispatch(
      setCartEngagementDataAction({ ...data, ...total }, data.orderType)
    );
    if (loyaltyEnabled) {
      let rewardsCart = rewardsCartEngagementSelector(getState());
      let productsCart = productsCartEngagementSelector(getState());
      changeCheckoutType(
        rewardsCart,
        productsCart,
        data,
        data.orderType,
        dispatch
      );
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const clearCartDataEngagement = () => async (dispatch) => {
  try {
    let resp = await postMethod(clearCartAPIUrl);
    if (resp.success) {
      dispatch(setCartClearAction());
    }
    return resp;
  } catch (error) {
    console.log(error);
  }
};
