const initialState = {
  navItems: {
    isAuth: false,
    token: "",
    changePasswordRequired: false,
    accountDetails: {},
  },
  homePage: null,
  cart: {
    items: [],
  },
  specialCart: {
    items: [],
  },
  cartItems: {
    items: [],
  },
  language: {
    languageData: [],
    selectedLanguage: "en",
  },
  product: {
    items: [],
  },
  brandSubBrand: {
    brand: null,
    subBrand: null,
  },
  orderHistory: {
    items: [],
  },
  creditAmount: {
    items: [],
  },
  myAccount: {},
  earnExtraPoints: {
    items: [],
    contentType: [],
    selectedTab: null,
  },
  redeemPoints: {
    items: [],
  },
  cartEngagement: {
    REWARDS: null,
    ZOR: null,
    checkoutType: null,
    isFetched: false,
  },
  checkOut: {
    termsDetails: null,
    customerDetails: null,
  },
  root: {
    loading: 0,
  },
  modals: {
    deliveryPreference: false,
  },
  rewardsHistory: {}
};

export default initialState;
