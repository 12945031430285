import { specialCartActionTypes } from "../actions/specialCart.actions";

export default function specialCartReducer(state = null, action) {
  switch (action.type) {
    case specialCartActionTypes.FETCH_SPECIAL_CART_DATA_SUCCESS:
      return { items: [...action.data] };
    default:
      return state;
  }
}
