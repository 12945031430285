import { getMethod } from "../../utils/api";
import { homeAPIUrl } from "./../../utils/urls";
import { fetchLogout } from "./navItems.actions";
export const homePageActionTypes = {
  FETCH_HOMEPAGE_DATA_SUCCESS: "FETCH_HOMEPAGE_DATA_SUCCESS",
  FETCH_HOMEPAGE_DATA_FAILURE: "FETCH_HOMEPAGE_DATA_FAILURE",
};

const fetchHomePageDataSuccessAction = (data) => {
  return {
    type: homePageActionTypes.FETCH_HOMEPAGE_DATA_SUCCESS,
    data,
  };
};

export const fetchGetHome = () => async (dispatch) => {
  try {
    // crate header as per isAuth
    let data = await getMethod(homeAPIUrl);
    if (data?.success === false) {
      dispatch(fetchLogout());
      localStorage.clear();
      return data;
    }
    dispatch(fetchHomePageDataSuccessAction(data));
    return data;
  } catch (error) {
    console.log(error);
  }
};
