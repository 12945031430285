import React, { Suspense } from "react";
import {
  // Redirect,
  Route,
  Switch,
  useHistory,
  // useLocation,
} from "react-router-dom";
import { Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import oktaAuth from "../utils/okta";
import Loading from "../components/common/Loading.component";
import { pages } from "./config";
import ProtectedRoute from "./ProtectedRoute";

// TODO: Replace protected route with okta secure route

const Routes = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const onAuthRequired = function () {
    history.push("/login");
  };
  return (
    <Suspense fallback={<Loading />}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Switch>
          {pages.map((page) =>
            page.secure ? (
              <ProtectedRoute path={page.path} exact={page.exact}>
                {page.component}
              </ProtectedRoute>
            ) : (
              <Route path={page.path} exact={page.exact}>
                {page.component}
              </Route>
            )
          )}
        </Switch>
      </Security>
    </Suspense>
  );
};

export default Routes;
