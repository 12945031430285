import { orderHistoryActionTypes } from "../actions/orderHistory.actions";

export default function orderHistoryReducer(state = null, action) {
  switch (action.type) {
    case orderHistoryActionTypes.FETCH_ORDER_HISTORY_DATA_SUCCESS:
      return {
        items: action.data.items,
        count: action.data.totalCount,
        orderTypes: action.data.orderTypes,
      };

    case orderHistoryActionTypes.FETCH_SELECTED_ORDER_DATA_SUCCESS:
      return {
        selectedOrder: action.data,
      };

    case orderHistoryActionTypes.CANCEL_ORDER_DATA_SUCCESS:
      return {
        ...state,
        items:
          state.items &&
          state.items.filter((singleItem) => {
            return singleItem.id === action.orderId
              ? (singleItem.releaseFlag = "D")
              : singleItem.releaseFlag;
          }),
        selectedOrder: state.selectedOrder
          ? { ...state.selectedOrder, releaseFlag: "D" }
          : state.selectedOrder,
      };

    case orderHistoryActionTypes.REMOVE_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          subOrders:
            state.selectedOrder.subOrders &&
            state.selectedOrder.subOrders.map((singleOrder, index) => {
              let updatedItems =
                singleOrder.items &&
                singleOrder.items.filter((singleSubItem) => {
                  return singleSubItem.id !== action.productId;
                });
              return {
                ...state.selectedOrder.subOrders[index],
                items: updatedItems,
              };
            }),
        },
      };

    case orderHistoryActionTypes.UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          subOrders:
            state.selectedOrder.subOrders &&
            state.selectedOrder.subOrders.map((singleOrder, index) => {
              let updatedItems =
                singleOrder.items &&
                singleOrder.items.filter((singleSubItem) => {
                  return singleSubItem.id === action.productInfo.id
                    ? (singleSubItem.returnQuantityUpdated = parseInt(
                        action.productQuantity
                      ))
                    : singleSubItem;
                });
              //if(updatedItems) {
              return {
                ...state.selectedOrder.subOrders[index],
                items: updatedItems,
              };
              //}
            }),
        },
      };

    case orderHistoryActionTypes.UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          subOrders:
            state.selectedOrder.subOrders &&
            state.selectedOrder.subOrders.map((singleOrder, index) => {
              let updatedItems =
                singleOrder.items &&
                singleOrder.items.filter((singleSubItem) => {
                  return singleSubItem.id === action.productInfo
                    ? (singleSubItem.returnReasonCodeUpdated =
                        action.productReasonCode)
                    : singleSubItem;
                });
              //if(updatedItems) {
              return {
                ...state.selectedOrder.subOrders[index],
                items: updatedItems,
              };
              //}
            }),
        },
      };

    case orderHistoryActionTypes.UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          subOrders:
            state.selectedOrder.subOrders &&
            state.selectedOrder.subOrders.map((singleOrder, index) => {
              let updatedItems =
                singleOrder.items &&
                singleOrder.items.filter((singleSubItem) => {
                  console.log("Reducer ~ singleSubItem", singleSubItem);
                  return singleSubItem.id === action.productId
                    ? (singleSubItem.imgUploaded = parseInt(action.payload))
                    : singleSubItem;
                });
              //if(updatedItems) {
              return {
                ...state.selectedOrder.subOrders[index],
                items: updatedItems,
              };
              //}
            }),
        },
      };

    default:
      return state;
  }
}
