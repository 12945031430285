import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { colors } from "./styles/variables";

const theme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: { 500: colors.primary },
  },
}));
export default theme;
