import React from "react";
import { Route } from "react-router-dom";
import Authentication from "../components/common/auth/Authentication";

const ProtectedRoute = ({ children, ...restProps }) => (
  <Route {...restProps}>
    <Authentication>{children}</Authentication>
  </Route>
);

export default ProtectedRoute;