import { LoginCallback } from "@okta/okta-react";
import { lazy } from "react";
import RouteRedirect from "./RouteRedirect";

/** Import Pages */
const Home = lazy(() => import("../pages/Home.page"));
const HomeEngagement = lazy(() => import("../pages/HomeEngagement.page"));
const Health = lazy(() => import("../pages/Health.page"));

const BulkUpload = lazy(() => import("../pages/BulkUpload.page"));
const CartPreview = lazy(() => import("../pages/CartPreview.page"));
const CartSummary = lazy(() => import("../pages/CartSummary.page"));
const ChangePassword = lazy(() => import("../pages/ChangePassword.page"));
const Contact = lazy(() => import("../pages/Contact.page"));
const CreditAmount = lazy(() => import("../pages/CreditAmount.page"));
const Help = lazy(() => import("../pages/Help.page"));
const MyAccount = lazy(() => import("../pages/MyAccount.page"));
const NotFound = lazy(() => import("../pages/NotFound.page"));
const OrderHistoryPage = lazy(() => import("../pages/OrderHistory.page"));
const OrderHistoryDetailPage = lazy(() =>
  import("../pages/OrderHistoryDetail.page")
);
const OrderReport = lazy(() => import("../pages/OrderReport.page"));
const OrderSummary = lazy(() => import("../pages/OrderSummary.page"));
const Wishlist = lazy(() => import("../pages/Wishlist.page"));

const Products = lazy(() => import("../pages/Products.page"));
const ReturnMaterial = lazy(() => import("../pages/ReturnMaterial.page"));
const SpecialProducts = lazy(() => import("../pages/SpecialProducts.page"));
const StatementOfAccount = lazy(() =>
  import("../pages/StatementOfAccount.page")
);
const SuggestedOrderDetail = lazy(() =>
  import("../pages/SuggestedOrderDetail.page")
);
const SuggestedOrderSummary = lazy(() =>
  import("../pages/SuggestedOrderSummary.page")
);

const EarnExtraPoints = lazy(() => import("../pages/EarnExtraPoints.page"));

const RedeemPoints = lazy(() => import("../pages/RedeemPoints.page"));

const ProductNew = lazy(() =>
  import("../components/loyaltyProgram/components/products/Products.page")
);

const RedeemPointsList = lazy(() => import("../pages/RedeemPointsList.page"));

const RewardHistory = lazy(() => import("../pages/RewardsHistory.page"));

const ArticleDetail = lazy(() => import("../pages/ArticleDetail.page"));

const RewardsRedeemed = lazy(() => import("../pages/RewardsRedeemed.page"));

const CheckoutEngagement = lazy(() =>
  import("../pages/CheckoutEngagement.page")
);

const CartEngagement = lazy(() => import("../pages/CartEngagement.page"));

const OrderSummaryEngagement = lazy(() =>
  import("../pages/OrderSummaryEngagement.page")
);

export const pages = [
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/home-engagement",
    component: <HomeEngagement />,
  },
  {
    path: "/health",
    component: <Health />,
  },
  {
    path: "/change-password",
    component: <ChangePassword />,
  },
  {
    path: "/404",
    component: <NotFound />,
  },
  {
    path: ["/shop", "/products"],
    component: <ProductNew />,
    exact: true,
    secure: true,
  },
  {
    path: ["/shop-old", "/products-old"],
    component: <Products />,
    exact: true,
    secure: true,
  },
  {
    path: "/special-products",
    component: <SpecialProducts />,
    exact: true,
    secure: true,
  },
  {
    path: "/return-material",
    component: <ReturnMaterial />,
    secure: true,
  },
  {
    path: "/cart-preview",
    component: <CartPreview />,
    secure: true,
  },
  {
    path: "/cart-summary",
    component: <CartSummary />,
    secure: true,
  },
  {
    path: "/bulk-upload",
    component: <BulkUpload />,
    secure: true,
  },
  {
    path: ["/order-history", "/order"],
    component: <OrderHistoryPage />,
    exact: true,
    secure: true,
  },
  {
    path: "/order-details/:orderId",
    component: <OrderHistoryDetailPage />,
    exact: true,
    secure: true,
  },
  {
    path: "/order-summary",
    component: <OrderSummary />,
    secure: true,
  },
  {
    path: "/credit-amount",
    component: <CreditAmount />,
    exact: true,
    secure: true,
  },
  {
    path: ["/buy_again", "/buy-again"],
    component: <Wishlist />,
    exact: true,
    secure: true,
  },
  {
    path: "/suggested-orders",
    component: <SuggestedOrderSummary />,
    exact: true,
    secure: true,
  },
  {
    path: "/order-report",
    component: <OrderReport />,
    exact: true,
    secure: true,
  },
  {
    path: "/suggested-order-detail/:id/:orderTitle",
    component: <SuggestedOrderDetail />,
    exact: true,
    secure: true,
  },
  {
    path: "/my-account",
    component: <MyAccount />,
    exact: true,
    secure: true,
  },
  {
    path: "/contact-us",
    component: <Contact />,
    exact: true,
    secure: true,
  },
  {
    path: "/help",
    component: <Help />,
    exact: true,
    secure: true,
  },
  {
    path: "/statement-of-account",
    component: <StatementOfAccount />,
    exact: true,
    secure: true,
  },
  /** loyalty pages **/
  {
    path: "/earn-extra-points",
    component: <EarnExtraPoints />,
    exact: true,
  },
  {
    path: "/redeem-points",
    component: <RedeemPoints />,
    exact: true,
  },
  {
    path: "/redeem-points-list",
    component: <RedeemPointsList />,
    exact: true,
  },
  {
    path: "/rewards-redeemed",
    component: <RewardsRedeemed />,
    exact: true,
  },
  {
    path: "/reward-history/:orderId",
    component: <RewardHistory />,
    exact: true,
  },
  {
    path: "/article/:articleId",
    component: <ArticleDetail />,
    exact: true,
  },
  {
    path: "/checkout-engagement",
    component: <CheckoutEngagement />,
    exact: true,
  },
  {
    path: "/cart-engagement",
    component: <CartEngagement />,
    exact: true,
  },
  {
    path: "/order-summary-engagement",
    component: <OrderSummaryEngagement />,
    exact: true,
  },
  {
    path: "/login/callback",
    component: <LoginCallback />,
  },
  {
    path: "/",
    component: <RouteRedirect />,
  },
];
