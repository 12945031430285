import moment from "moment";

/**
 * ======= Common Utility methods =======
 **/

/**
 * Get unique item from array of objects
 * @param {array} arr
 * @param {string} key
 * @returns {array}
 */
export const uniqueArrItems = (arr, key) => {
  let data = [];
  for (let item of arr) {
    let value = item[key];
    if (data.indexOf(value) === -1) {
      data.push(value);
    }
  }
  return data;
};
/**
 * fromat content data from content api data
 * @param {array} contentData
 * @returns {object}
 */
export const formatContentData = (contentData = []) => {
  let featured = [];
  let contentObj = {};
  let contentType = [];
  for (let item of contentData) {
    let value = item.contentType;
    if (
      value !== "Site" &&
      value !== "Banner" &&
      value !== "Promo" &&
      value !== "BrandImages" &&
      contentType.indexOf(value) === -1
    ) {
      contentType.push(value);
    }
    let hasFeatured = item?.tags?.find((tag) => tag.slug === "featured");
    if (hasFeatured) {
      featured.push(item);
    }
    if (contentObj[value]) {
      contentObj[value].push(item);
    } else {
      contentObj[value] = [item];
    }
  }
  contentObj.featured = featured;
  if (featured.length) {
    contentType = ["featured", ...contentType];
  }
  return { contentType, contentObj };
};
/**
 * Format article date
 * @param {string} dateStr
 * @returns {string}
 */
export const formatDate = (dateStr, format = "MMM DD, YYYY") => {
  if (dateStr) {
    return moment(dateStr).format(format);
  }
  return "";
};
/**
 * Comapare two dates and return boolean
 * @param {string} date1
 * @param {string} date2
 * @returns {boolean}
 */
export const compareDate = (date1, date2) => {
  let formattedDate1 = moment(date1).format("YYYY-MM-DD");
  let formattedDate2 = moment(date2).format("YYYY-MM-DD");
  return formattedDate1 < formattedDate2;
};
/**
 * Order types
 */
export const ORDER_TYPES = {
  REWARDS: "REWARDS",
  ZOR: "ZOR",
};
/**
 * Create add reward body
 * @param {object} product
 * @returns {object}
 */
export const createAddCartData = (
  orderType,
  product,
  quantity = 0,
  items = []
) => {
  let itemUpdated = false;
  let newItems = [];
  let productData = {
    id: product.id,
    quantity,
  };

  /**
   * Delete item from cart
   */
  if (!quantity) {
    return {
      orderType,
      items: [
        {
          id: product.id,
          quantity: 0,
        },
      ],
    };
  }

  /**
   * Add / Update cart item
   **/
  if (items.length) {
    newItems = items.map((item) => {
      if (item.id === product.id) {
        itemUpdated = true;
        if (orderType === ORDER_TYPES.ZOR) {
          productData.unitType = item.unitType;
        }
        return productData;
      }
      let updatedItem = {
        id: item.id,
        quantity: item.count,
      };
      if (orderType === ORDER_TYPES.ZOR) {
        updatedItem.unitType = item.unitType;
      }
      return updatedItem;
    });
  } else {
    itemUpdated = true;
    newItems.push(productData);
  }
  if (!itemUpdated && items.length) {
    newItems = [...newItems, productData];
  }
  return {
    orderType,
    items: newItems,
  };
};
/**
 * Create add activity body from article
 * @param {object} product
 * @returns {object}
 */
export const createAddActivityData = (article) => {
  return {
    activityId: article?.activity?.activityId || "",
    points: article?.activity?.points || "",
    identifier: "",
    tags: "",
    metadata: "",
  };
};

/**
 * Count total reward points add total items count for rewards
 * @param {array} rewards
 * @returns {object}
 */
export const countPointsAndTotalRewards = (rewards) => {
  return rewards.reduce(
    (total, item) => {
      total.totalCount = item.count
        ? parseInt(item.count) + total.totalCount
        : total.totalCount;
      total.totalPoints = item.points
        ? parseInt(item.points) * parseInt(item.count || 0) + total.totalPoints
        : total.totalPoints;
      return total;
    },
    { totalCount: 0, totalPoints: 0 }
  );
};

/**
 * Convert array to json object with a key
 * @param {array} arr
 * @returns {string} key
 */
export const arrToJSON = (arr, key) => {
  let data = {};
  for (let item of arr) {
    let id = item[key];
    data[id] = { ...item };
  }
  return data;
};
