import moment from "moment";

// TO GET FORMATED PRICE TO 2 DECIMALS
export const getFormatedPrice = (num) => {
  return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
};

// TO GET FORMATED DATE
export const getFormatedDate = (date, formatType) => {
  return moment(date).format(formatType);
};

// TO GET CURRENT DATE
export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

// TO GET SELECTED ASSOCIATED CUSTOMER
export const getSelectedAssociatedCustomer = () => {
  return localStorage.getItem("selectedCustomerID");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Type
export const getSelectedAssociatedCustomerType = () => {
  return localStorage.getItem("selectedCustomerType");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Sap ID
export const getSelectedAssociatedCustomerSapId = () => {
  return localStorage.getItem("selectedCustomerSapId");
};

// TO GET SELECTED ASSOCIATED CUSTOMER Credit Amount
export const getSelectedAssociatedCustomerCreditAmt = () => {
  return localStorage.getItem("selectedCustomerCreditAmt");
};

export const getDesktopDatePickerFormatedDate = (date, formatType) => {
  return moment(date).toDate(formatType);
};
