import { getMethod, postMethod } from "../../utils/api";
import { getHeaderToken } from "../selectors/navItems.selector";
import {
  clearCartAPIUrl,
  getCartAPIUrl,
  updateCartAPIUrl,
} from "./../../utils/urls";
// import { deleteOrderDataAction } from "./orderHistory.actions";
import { loadingEnd, loadingStart } from "./root.actions";
export const cartItemsActionTypes = {
  FETCH_CART_ITEMS_SUCCESS: "FETCH_CART_ITEMS_SUCCESS",
  FETCH_CART_ITEMS_FAILURE: "FETCH_CART_ITEMS_FAILURE",
  ADD_CART_ITEMS_SUCCESS: "ADD_CART_ITEMS_SUCCESS",
  ADD_CART_ITEMS_FAILURE: "ADD_CART_ITEMS_FAILURE",
  GET_CART_ITEMS_SUCCESS: "GET_CART_ITEMS_SUCCESS",
  GET_CART_ITEMS_FAILURE: "GET_CART_ITEMS_FAILURE",
  FETCH_CART_CARTITEMS_SUCCESS: "FETCH_CART_CARTITEMS_SUCCESS",
};

const fetchCartItemsDataSuccessAction = (data, productsCount = null) => {
  return {
    type: cartItemsActionTypes.FETCH_CART_ITEMS_SUCCESS,
    data,
    productsCount,
  };
};

const fetchCartCartItemsDataSuccessAction = (data) => {
  return {
    type: cartItemsActionTypes.FETCH_CART_CARTITEMS_SUCCESS,
    data,
  };
};

// const getCartItemsDataSuccessAction = (data, productsCount = null) => {
//   return {
//     type: cartItemsActionTypes.GET_CART_ITEMS_SUCCESS,
//     data,
//     productsCount,
//   };
// };

//Product Listing Page on Add to Cart Button
export const moveCartToCartItemsDataAction =
  (isAuth, token, orderType = "ZOR") =>
  async (dispatch, getState) => {
    const latestState = getState();
    const cart = latestState.cart.items;

    // get product id and update count
    let updateCartPayload = {
      orderType: orderType,
      items: cart.map((cartData) => ({
        id: cartData.id,
        quantity:
          cartData.count === 0 || cartData.count === "" ? 0 : cartData.count,
        unitType: cartData.unitType
          .filter((type) => type.active === 1)
          .map((singleUnitType) => {
            return singleUnitType.key;
          })[0],
      })),
    };

    return await postMethod(updateCartAPIUrl, updateCartPayload)
      .then((data) => {
        let productsWithCount = {};
        if (data) {
          let defaultCount = 0;
          let totalQuantityCount = 0;
          data.items &&
            data.items.length > 0 &&
            data.items.forEach((cartData) => {
              productsWithCount[cartData.id] = cartData.count;
              defaultCount += 1;
              totalQuantityCount += parseInt(cartData.count);
              // defaultCount = parseInt(cartData.count) + parseInt(defaultCount);
            });
          productsWithCount["defaultCount"] = parseInt(defaultCount);
          productsWithCount["totalQuantityCount"] = totalQuantityCount;
        }
        if (data.items) {
          dispatch(fetchCartItemsDataSuccessAction(data, productsWithCount));
        }
        return data;
      })
      .catch((err) => console.log(err));
  };

export const moveSpecialCartToCartItemsDataAction =
  (isAuth, token, orderType = "ZOR") =>
  async (dispatch, getState) => {
    const latestState = getState();
    const specialCart = latestState.specialCart.items;

    // get product id and update count
    let updateCartPayload = {
      orderType: orderType,
      items: specialCart.map((cartData) => ({
        id: cartData.id,
        quantity:
          cartData.count === 0 || cartData.count === "" ? 0 : cartData.count,
        unitType: cartData.unitType
          .filter((type) => type.active === 1)
          .map((singleUnitType) => {
            return singleUnitType.key;
          })[0],
      })),
    };

    return await postMethod(updateCartAPIUrl, updateCartPayload)
      .then((data) => {
        let productsWithCount = {};
        if (data) {
          let defaultCount = 0;
          let totalQuantityCount = 0;
          data.items &&
            data.items.length > 0 &&
            data.items.forEach((cartData) => {
              productsWithCount[cartData.id] = cartData.count;
              defaultCount += 1;
              totalQuantityCount += parseInt(cartData.count);
              // defaultCount = parseInt(cartData.count) + parseInt(defaultCount);
            });
          productsWithCount["defaultCount"] = parseInt(defaultCount);
          productsWithCount["totalQuantityCount"] = totalQuantityCount;
        }
        if (data.items) {
          dispatch(fetchCartItemsDataSuccessAction(data, productsWithCount));
        }
        return data;
      })
      .catch((err) => console.log(err));
  };

// Clear Cart event of Cart Preview Page
// No need to pass token it is coming form selector
export const clearCartItemsDataAction =
  (token) => async (dispatch, getState) => {
    dispatch(loadingStart());
    let latestState = getState();
    token = getHeaderToken(latestState);
    latestState.cartItems.items = [];
    latestState.cartItems.getCartAPI = null;
    if (latestState.cartItems.orderDetails) {
      latestState.cartItems.orderDetails = null;
    }
    if (latestState.checkOut) {
      latestState.checkOut = null;
    }
    if (latestState.cartItems.productsCount) {
      latestState.cartItems.productsCount = null;
    }
    if (latestState.product.items && latestState.product.items.items) {
      latestState.product.items.items.map((item) => {
        if (item.count > 0) {
          return (item.count = 0);
        }
        return "";
      });
    }
    return await postMethod(clearCartAPIUrl)
      .then((data) => {
        console.log(
          "🚀 ~ file: cartItems.actions.js ~ line 203 ~ .then ~ data",
          data
        );
        dispatch(fetchCartItemsDataSuccessAction(data));
        return data;
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

/*
This action called from product page and special product
latestState.product.items.items.map will not update product count
Note: we can merge with clearCartItemsDataAction action also later
  */
export const clearCartFromProductDataAction =
  (token) => async (dispatch, getState) => {
    console.log(" - - - - -");
    dispatch(loadingStart());
    let latestState = getState();
    token = getHeaderToken(latestState);
    latestState.cartItems.items = [];
    latestState.cartItems.getCartAPI = null;
    if (latestState.cartItems.orderDetails) {
      latestState.cartItems.orderDetails = null;
    }
    if (latestState.checkOut) {
      latestState.checkOut = null;
    }
    if (latestState.cartItems.productsCount) {
      latestState.cartItems.productsCount = null;
    }
    // if (latestState.product.items && latestState.product.items.items) {
    //   latestState.product.items.items.map((item) => {
    //     if (item.count > 0) {
    //       return (item.count = 0);
    //     }
    //     return "";
    //   });
    // }

    await postMethod(clearCartAPIUrl)
      .then((data) => {
        console.log(
          "🚀 ~ file: cartItems.actions.js ~ line 203 ~ .then ~ data",
          data
        );
        dispatch(fetchCartItemsDataSuccessAction(data));
        return data;
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

export const updateCartItemsDataAction =
  (productInfo) => (dispatch, getState) => {
    const latestState = getState();
    const cartItem = latestState.cartItems.items.find(
      (item) => item.id === productInfo.id
    );
    if (!cartItem) {
      productInfo.count = parseInt(productInfo.count) + 1;
      latestState.cartItems.items.push(productInfo);
    }
    dispatch(loadingStart());
    // TODO: need to be changed to actual API call implementation
    // setTimeout is just for demonstration purposes
    setTimeout(() => {
      dispatch(loadingEnd());
      dispatch(fetchCartItemsDataSuccessAction(latestState.cartItems.items));
    }, 500);
  };

//Cart Preview Page Quantity Update
export const updateCartItemsInputDataAction =
  (productInfo, value, isAuth, token, orderType = "ZOR") =>
  async (dispatch, getState) => {
    //if (value >= 1) {
    const latestState = getState();
    const cartItems = latestState.cartItems.items;
    let productsWithCount = latestState.cartItems.productsCount;
    let qtyArr = [];
    if (Array.isArray(value)) {
      cartItems.map((cartItem, key) => {
        value.map((qtyObj, key) => {
          if (cartItem.id === qtyObj.id) {
            qtyArr.push(qtyObj.count);
          }
        });
      });
    }
    dispatch(loadingStart());
    let updateCartPayload = {
      orderType: orderType,
      items: cartItems.map((cartItem, key) => ({
        id: cartItem.id,
        quantity: Array.isArray(productInfo)
          ? qtyArr[key]
          : cartItem.id === productInfo.id
          ? value
          : cartItem.count,
        unitType: cartItem.unitType
          .filter((type) => type.active === 1)
          .map((singleUnitType) => {
            return singleUnitType.key;
          })[0],
      })),
    };

    return await postMethod(updateCartAPIUrl, updateCartPayload)
      .then((data) => {
        if (value && value !== "" && productsWithCount) {
          let defaultCount = productsWithCount.defaultCount;
          defaultCount = 0;
          let totalQuantityCount = 0;
          data.items.forEach((cartData) => {
            //let updatedValue = cartData.count;
            // if(cartData.id === productInfo.id && value && value !== "") {
            //   updatedValue = value;
            // }
            // productsWithCount[productInfo.id] = updatedValue;
            defaultCount += 1;
            totalQuantityCount += parseInt(cartData.count);
          });
          productsWithCount["defaultCount"] = parseInt(defaultCount);
          productsWithCount["totalQuantityCount"] = totalQuantityCount;
        }
        dispatch(fetchCartItemsDataSuccessAction(data, productsWithCount));
        return data;
      })
      .catch((err) => console.log(err));
    //}
  };

// Delete single cart item from Cart Preview Page
export const deleteCartItemsDataAction =
  (itemsId, token, orderType = "ZOR") =>
  async (dispatch, getState) => {
    dispatch(loadingStart());
    let latestState = getState();
    let productsWithCount = latestState.cartItems.productsCount;
    // const cartItems = latestState.cartItems.items;

    const items = Array.isArray(itemsId)
      ? itemsId
      : [
          {
            id: itemsId,
            quantity: 0,
          },
        ];
    let updateCartPayload = {
      orderType: orderType,
      items: items,
    };

    return await postMethod(updateCartAPIUrl, updateCartPayload)
      .then((data) => {
        if (itemsId && itemsId !== "" && productsWithCount) {
          let defaultCount = productsWithCount.defaultCount;
          defaultCount = 0;
          let totalQuantityCount = 0;
          data.items.forEach((cartData) => {
            defaultCount += 1;
            totalQuantityCount += parseInt(cartData.count);
          });
          productsWithCount["defaultCount"] = parseInt(defaultCount);
          productsWithCount["totalQuantityCount"] = totalQuantityCount;
        }
        dispatch(fetchCartItemsDataSuccessAction(data, productsWithCount));
        return data;
      })
      .catch((err) => console.log(err));
  };

// Decrease Product using (-) Button
export const decreaseCartItemsDataAction =
  (productInfo) => (dispatch, getState) => {
    let latestState = getState();
    let cartItem = latestState.cartItems.items.find(
      (item) => item.id === productInfo.id
    );
    if (cartItem) {
      cartItem.count--;
    }

    if (cartItem && cartItem.count === 0) {
      // remove cartItem.id from cart
      latestState.cartItems.items = latestState.cartItems.items.filter(
        (item) => item.id !== productInfo.id
      );
    }
    dispatch(loadingStart());

    // TODO: need to be changed to actual API call implementation
    setTimeout(() => {
      dispatch(loadingEnd());
      dispatch(fetchCartItemsDataSuccessAction(latestState.cartItems.items));
    }, 500);
  };

export const getCartItemsDataAction = (token) => async (dispatch, getState) => {
  dispatch(loadingStart());
  let latestState = getState();

  return await getMethod(getCartAPIUrl)
    .then((data) => {
      console.log("data... ", data);
      let finalData = latestState.cartItems;
      finalData.getCartAPI = data;
      finalData.items = data.items;
      // console.log("1--1-");
      let productsWithCount = {};
      let defaultCount = 0;
      let totalQuantityCount = 0;
      if (finalData.items !== undefined) {
        finalData.items.forEach((cartData) => {
          productsWithCount[cartData.id] = cartData.count;
          defaultCount += 1;
          totalQuantityCount += parseInt(cartData.count);
          // defaultCount = parseInt(cartData.count) + parseInt(defaultCount);
        });
      }
      productsWithCount["defaultCount"] = parseInt(defaultCount);
      productsWithCount["totalQuantityCount"] = totalQuantityCount;
      dispatch(fetchCartItemsDataSuccessAction(data, productsWithCount));
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => console.log(err));
};

// Change Unitype in Cart Preview Page
export const updateUnitTypeDataAction =
  (productId, unitType) => (dispatch, getState) => {
    dispatch(loadingStart());
    const latestState = getState();

    // TO UPDATE CART ITEMS STORE
    latestState.cartItems.items.map((singleItem) => {
      return singleItem.id === productId
        ? singleItem.unitType.map((singleType) => {
            return singleType.key === unitType
              ? (singleType.active = 1)
              : singleType.key !== unitType
              ? (singleType.active = 0)
              : singleType.active;
          })
        : singleItem;
    });

    // TO UPDATE CART STORE
    latestState.cart.items.map((singleCartItem) => {
      return singleCartItem.id === productId
        ? singleCartItem.unitType.map((singleType) => {
            return singleType.key === unitType
              ? (singleType.active = 1)
              : singleType.key !== unitType
              ? (singleType.active = 0)
              : singleType.active;
          })
        : singleCartItem;
    });

    dispatch(
      fetchCartItemsDataSuccessAction(
        latestState.cartItems,
        latestState.cartItems.productsCount
      )
    );
    dispatch(loadingEnd());
  };

//Bulk Upload: Import Products To Cart
export const moveImportProductsToCartItemsDataAction =
  (updateCartPayload, isAuth, token) => async () => {
    await postMethod(updateCartAPIUrl, updateCartPayload)
      .then((data) => {
        return data;
      })
      .catch((err) => console.log(err));
  };
