import { getMethod, postMethod } from "../../utils/api";
import { productListAPIUrl, specialProductListAPIUrl } from "../../utils/urls";
import {
  productAddToWishListAPIUrl,
  productRemoveToWishListAPIUrl,
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

/* 
1> Update store with user action data (Sync)
2> Update the backend with user action data (Async)
3> Get the latest cart data on page load. (Async)

*/
export const productActionTypes = {
  FETCH_PRODUCT_DATA_SUCCESS: "FETCH_PRODUCT_DATA_SUCCESS",
  FETCH_PRODUCT_DATA_FAILURE: "FETCH_PRODUCT_DATA_FAILURE",
  FETCH_WISHLIST_DATA_SUCESS: "FETCH_WISHLIST_DATA_SUCESS",
  FETCH_WISHLIST_DATA_FAILURE: "FETCH_WISHLIST_DATA_FAILURE",
  FETCH_UPDATE_PRODUCT_DATA_SUCCESS: "FETCH_UPDATE_PRODUCT_DATA_SUCCESS",
};

const fetchProductDataSuccessAction = (data) => {
  return {
    type: productActionTypes.FETCH_PRODUCT_DATA_SUCCESS,
    data,
  };
};

const getWishListItemsDataSuccessAction = (productId, value) => {
  return {
    type: productActionTypes.FETCH_WISHLIST_DATA_SUCESS,
    productId,
    value,
  };
};

const fetchUpdateProductDataSuccessAction = (productId, unitType) => {
  return {
    type: productActionTypes.FETCH_UPDATE_PRODUCT_DATA_SUCCESS,
    productId,
    unitType,
  };
};

export const fetchProductDataAction =
  (payload, isAuth, token, isSpecialProduct = false) =>
  async (dispatch, getState) => {
    const apiURL = isSpecialProduct
      ? specialProductListAPIUrl
      : productListAPIUrl;
    return await postMethod(apiURL, payload)
      .then((data) => {
        dispatch(fetchProductDataSuccessAction(data));
        dispatch(loadingEnd());
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

// Add to Wishlist from Product Listing Page
export const addToWishListDataAction =
  (productInfo, token) => async (dispatch, getState) => {
    dispatch(loadingStart());
    await getMethod(productAddToWishListAPIUrl + "/" + productInfo.id)
      .then((data) => {
        dispatch(getWishListItemsDataSuccessAction(productInfo.id, 1));
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

// Remove to Wishlist from Product Listing Page
export const removeToWishListDataAction =
  (productInfo, token) => async (dispatch, getState) => {
    dispatch(loadingStart());
    await getMethod(productRemoveToWishListAPIUrl + "/" + productInfo.id)
      .then((data) => {
        dispatch(getWishListItemsDataSuccessAction(productInfo.id, 0));
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
  };

// Change Unitype in Product Listing Page
export const updateUnitTypeDataAction =
  (productId, unitType) => (dispatch, getState) => {
    dispatch(loadingStart());
    const latestState = getState();
    // TO UPDATE CART STORE
    latestState.cart.items.map((singleCartItem) => {
      return singleCartItem.id === productId
        ? singleCartItem.unitType.map((singleType) => {
            return singleType.key === unitType
              ? (singleType.active = 1)
              : singleType.key !== unitType
              ? (singleType.active = 0)
              : singleType.active;
          })
        : singleCartItem;
    });

    dispatch(fetchUpdateProductDataSuccessAction(productId, unitType));
    dispatch(loadingEnd());
  };

// FOR DISPLAYING RECORDS IN EXCEL (WITHOUT PAGINATION, ALL RECORDS)
export const fetchProductExcelAction =
  (token, isSpecialProduct = false) =>
  async (dispatch) => {
    dispatch(loadingStart());
    const apiURL = JSON.parse(isSpecialProduct)
      ? specialProductListAPIUrl
      : productListAPIUrl;
    return await postMethod(apiURL)
      .then((data) => {
        return data;
      })
      .catch((err) => console.log(err));
  };
