export const allHeaderData = (state) =>
  state.navItems ? state.navItems : null;
export const getHeaderNavData = (state) =>
  state.navItems?.isAuth
    ? state.navItems.authenticatedNav?.appHeader
    : state.navItems.anonymousNav?.appHeader;
export const getFooterNavData = (state) =>
  state.navItems?.isAuth
    ? state.navItems.authenticatedNav?.appFooter
    : state.navItems.anonymousNav?.appFooter;

export const getAssociatedCustomers = (state) =>
  state.navItems?.isAuth
    ? state.navItems.authenticatedNav?.associatedCustomers
    : [];

export const getHeaderToken = (state) =>
  state.navItems.token ? state.navItems.token : null;
export const getUserType = (state) =>
  state.navItems.userType ? state.navItems.userType : null;
export const isAuthenticated = (state) => state.navItems.isAuth;

export const getChangePasswordRequired = (state) =>
  state.navItems.changePasswordRequired
    ? state.navItems.changePasswordRequired
    : false;

export const getCustomerArchiveData = (state) =>
  state.navItems?.archiveData ? state.navItems.archiveData : {};

export const getAccountDetails = (state) =>
  state.navItems.accountDetails ? state.navItems.accountDetails : {};
export const getRewardPoints = (state) =>
  state.navItems?.accountDetails?.rewardPoints ? state.navItems.accountDetails?.rewardPoints : "00";
