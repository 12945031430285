import { getMethod, postMethod } from "../../utils/api";
import {
  accessTokenAPIUrl,
  apiHeaderWithSiteId,
  changePasswordUrl,
  checkSupervisorUrl,
  forgotPasswordAPIUrl,
  getAccountDetailsAPIUrl,
  getCustomerArchiveStatusAPIUrl,
  getEnv,
  getNavigationsAPIUrl,
} from "../../utils/urls";
import {
  getHeaderToken,
  isAuthenticated,
} from "../selectors/navItems.selector";
import { loadingEnd, loadingStart, loggedOut } from "./root.actions";

export const navItemsActionTypes = {
  FETCH_NAV_ITEMS_DATA_SUCCESS: "FETCH_NAV_ITEMS_DATA_SUCCESS",
  CUSTOMER_ARCHIVE_SUCCESS: "CUSTOMER_ARCHIVE_SUCCESS",
  FETCH_NAV_ITEMS_DATA_FAILURE: "FETCH_NAV_ITEMS_DATA_FAILURE",
  VALIDATE_TOKEN_SUCCESS: "VALIDATE_TOKEN_SUCCESS",
  CHANGE_PASSWORD_TOKEN_SUCCESS: "CHANGE_PASSWORD_TOKEN_SUCCESS",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  ACCOUNT_DETAILS_SUCCESS: "ACCOUNT_DETAILS_SUCCESS",
};

const fetchHeaderDataSuccessAction = (data, isAuth) => {
  return {
    type: navItemsActionTypes.FETCH_NAV_ITEMS_DATA_SUCCESS,
    isAuth,
    data,
  };
};

const validateTokenSuccessAction = (
  token,
  userType,
  isAuth,
  changePasswordRequired
) => {
  return {
    type: navItemsActionTypes.VALIDATE_TOKEN_SUCCESS,
    isAuth,
    userType,
    token,
    changePasswordRequired,
  };
};

const changePasswordSuccessAction = (isAuth, token) => {
  return {
    type: navItemsActionTypes.CHANGE_PASSWORD_TOKEN_SUCCESS,
    isAuth,
    token,
  };
};

const forgotPasswordSuccessAction = (token, isAuth) => {
  return {
    type: navItemsActionTypes.FORGOT_PASSWORD_SUCCESS,
    isAuth,
    token,
  };
};

const customerArchiveSuccessAction = (data, isAuth) => {
  return {
    type: navItemsActionTypes.CUSTOMER_ARCHIVE_SUCCESS,
    isAuth,
    data,
  };
};

// Login api Call
export const fetchGetAccessToken = (payload) => (dispatch) => {
  dispatch(loadingStart());
  return postMethod(accessTokenAPIUrl, payload).then((data) => {
    if (data && data.success === false) {
      // throw new Error(data.msg);
      dispatch(loadingEnd());

      return data;
    } else {
      let changePasswordRequired = data.changePasswordRequired
        ? data.changePasswordRequired
        : false;
      dispatch(
        validateTokenSuccessAction(
          data.token,
          data.userType,
          true,
          changePasswordRequired
        )
      );
      dispatch(loadingEnd());
      return data;
    }
  });
};

// checkSupervisor api Call
export const fetchGetCheckSupervisor =
  (token, isAuth) => (dispatch, getState) => {
    const latestState = getState();
    const updateIsAuth = isAuth ? isAuth : isAuthenticated(latestState);
    let headerData = { ...latestState.navItems.authenticatedNav };

    dispatch(loadingStart());

    return getMethod(checkSupervisorUrl)
      .then((data) => {
        headerData.associatedCustomers = data.associatedCustomers;
        // if (
        //   headerData.associatedCustomers.length === 1 &&
        //   headerData.associatedCustomers[0].blocked
        // ) {
        //   dispatch(fetchHeaderDataSuccessAction(headerData, isAuth));
        // } else {
        // dispatch(
        //   validateTokenSuccessAction(
        //     token,
        //     userType,
        //     true,
        //     changePasswordRequired
        //   )
        // );
        dispatch(fetchHeaderDataSuccessAction(headerData, updateIsAuth));
        // }
        return data;
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingEnd());
      });
  };

// Customer Archive Status api Call
export const getCustomerArchiveStatus =
  (token, isAuth) => (dispatch, getState) => {
    dispatch(loadingStart());
    return getMethod(getCustomerArchiveStatusAPIUrl)
      .then((data) => {
        dispatch(customerArchiveSuccessAction(data, isAuth));
        return data;
      })
      .catch((err) => console.log(err));
  };

export const updateLoginDetails =
  (token, userType, changePasswordRequired) => (dispatch) => {
    dispatch(loadingStart());
    dispatch(
      validateTokenSuccessAction(token, userType, true, changePasswordRequired)
    );
    dispatch(loadingEnd());
  };

export const fetchGetNavigation = () => async (dispatch, getState) => {
  try {
    const latestState = getState();
    const isAuth = isAuthenticated(latestState);

    dispatch(loadingStart());
    // crate header as per isAuth
    let headers = {};
    if (localStorage.getItem("language") && getEnv().siteId === "1") {
      headers["Content-Language"] = localStorage.getItem("language");
    }
    let data = await getMethod(getNavigationsAPIUrl, {
      headers: headers,
    });
    // TODO: handel error
    if (data?.appHeader?.creditBalance) {
      localStorage.setItem(
        "selectedCustomerCreditAmt",
        data.appHeader.creditBalance
      );
    }
    dispatch(fetchHeaderDataSuccessAction(data, isAuth));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const changeLanguageAction =
  (userTypeData) => async (dispatch, getState) => {
    dispatch(loadingStart());
    let latestState = getState();
    const token = getHeaderToken(latestState);

    // dispatch(validateTokenSuccessAction(token, false));
    // if (latestState.homePage) {
    //   latestState.homePage = null;
    // }
    // if (latestState.navItems) {
    //   latestState.navItems.userType = userTypeData;
    // }
    // if (latestState.navItems) {
    //   // latestState.navItems = null;
    //   latestState.navItems.anonymousNav = null;
    // }
    // if (latestState.checkOut) {
    //   latestState.checkOut = null;
    // }
    // if (latestState.cart) {
    //   latestState.cart.items = [];
    // }
    // if (latestState.myAccount) {
    //   latestState.myAccount = null;
    // }
    // // To clear the cartItems
    // if (latestState.cartItems) {
    //   latestState.cartItems.items = [];
    //   latestState.cartItems = [];
    // }
    // if (latestState.product) {
    //   latestState.product.items.items = [];
    //   latestState.product.items.orderType = null;
    //   latestState.product.items.totalCount = null;
    // }
    // if (latestState.brandSubBrand) {
    //   latestState.brandSubBrand.brand = null;
    //   latestState.brandSubBrand.subBrand = null;
    // }
    // if (latestState.orderHistory) {
    //   latestState.orderHistory.items = [];
    // }
    // if (latestState.creditAmount) {
    //   latestState.creditAmount.items = [];
    // }
    // }
  };

export const fetchLogout = () => async (dispatch) => {
  dispatch(loadingStart());
  dispatch(loggedOut());
  dispatch(validateTokenSuccessAction("", false));
  localStorage.removeItem("selectedCustomerID");
  localStorage.removeItem("selectedCustomerType");
  localStorage.removeItem("selectedCustomerSapId");
  localStorage.removeItem("selectedCustomerCreditAmt");
  localStorage.removeItem("persist:root");
  localStorage.clear();
  if (apiHeaderWithSiteId["AssociatedCustomer"]) {
    delete apiHeaderWithSiteId["AssociatedCustomer"];
  }
};

// Forgotpassword api Call
export const forgotPassword = (payload) => (dispatch) => {
  dispatch(loadingStart());
  return postMethod(forgotPasswordAPIUrl, payload)
    .then((data) => {
      if (data && data.success === false) {
        dispatch(loadingEnd());
        return data;
      } else {
        dispatch(forgotPasswordSuccessAction(data.token, true));
        dispatch(loadingEnd());
        return data;
      }
    })
    .catch((err) => console.log(err));
};

// ChangePassword api Call
export const postChangePassword = (payload, token) => (dispatch) => {
  dispatch(loadingStart());
  return postMethod(changePasswordUrl, payload).then((data) => {
    if (data && data.success === false) {
      // throw new Error(data.msg);
      dispatch(loadingEnd());
      return data;
    } else {
      let isAuth = token ? true : false;
      let tokenData = data.newToken ? data.newToken : "";
      dispatch(changePasswordSuccessAction(isAuth, tokenData));
      dispatch(loadingEnd());
      return data;
    }
  });
};

export const clearArchiveDataAction = (token) => async (dispatch, getState) => {
  dispatch(loadingStart());
  if (token) {
    let latestState = getState();
    const isAuth = isAuthenticated(latestState);
    if (latestState.navItems.archiveData) {
      latestState.navItems.archiveData.archiveBox = "";
      latestState.navItems.archiveData.contract = "";
    }
    const data = latestState.navItems.archiveData;
    dispatch(customerArchiveSuccessAction(data, isAuth));
  }
};

// Clear REDUX on Change Customer
export const clearReduxDataAction = (token) => async (dispatch, getState) => {
  dispatch(loadingStart());
  if (token) {
    let latestState = getState();
    if (latestState.homePage) {
      latestState.homePage = null;
    }
    if (latestState.cart) {
      latestState.cart.items = [];
    }
    // To clear the cartItems
    if (latestState.cartItems) {
      latestState.cartItems.items = [];
    }
    if (latestState.navItems.changePasswordRequired) {
      latestState.navItems.changePasswordRequired = false;
    }
    if (latestState.product) {
      latestState.product.items.items = [];
      latestState.product.items.orderType = null;
      latestState.product.items.totalCount = null;
    }
    if (latestState.brandSubBrand) {
      latestState.brandSubBrand.brand = null;
      latestState.brandSubBrand.subBrand = null;
    }
    if (latestState.orderHistory) {
      latestState.orderHistory.items = [];
    }
    if (latestState.creditAmount) {
      latestState.creditAmount.items = [];
    }
  }
};

const fetchAccountDetailsSuccessAction = (data) => {
  return {
    type: navItemsActionTypes.ACCOUNT_DETAILS_SUCCESS,
    data,
  };
};

export const getAccountDetailsAPI = () => async (dispatch) => {
  try {
    let data = await getMethod(getAccountDetailsAPIUrl);
    dispatch(fetchAccountDetailsSuccessAction(data));
    return data;
  } catch (error) {
    console.log(error);
  }
};
