import React from "react";
import { Redirect, useLocation } from "react-router-dom";

const RouteRedirect = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); // url - /home?langauge=en
  const path = searchParams.get("path"); // https://exmaple.com/?path=/products

  if (path) {
    return <Redirect to={path} />;
  } else {
    // return <Home />;
    return <Redirect exact from="/" to="/home" />;
  }
};

export default RouteRedirect;
