import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { fetchLogout } from "../../../store/actions/navItems.actions";
import { allHeaderData } from "../../../store/selectors/navItems.selector";
import { apiHeaderWithSiteId, validateTokenAPIUrl } from "../../../utils/urls";
import { getSelectedAssociatedCustomer, getSelectedAssociatedCustomerCreditAmt } from "../helper/helper";

const Authentication = ({ children }) => {
  const headerData = useSelector(allHeaderData);
  const isUserAuth = headerData.isAuth;
  const token = headerData.token ? headerData.token : null;
  const [isValidToken, setIsValidToken] = useState(isUserAuth);
  const history = useHistory();
  const dispatch = useDispatch();
  let headers = apiHeaderWithSiteId;
  headers.Authorization = `Bearer ${token}`;
  if(getSelectedAssociatedCustomer() && headers.AssociatedCustomer === undefined) {
    headers.AssociatedCustomer = getSelectedAssociatedCustomer();
  }
  useEffect(() => {
    fetch(validateTokenAPIUrl, {
      method: "GET",
      headers: headers,
    }).then((res) => {
      res.json().then((data) => {
        if (data.success === true) {
          setIsValidToken(true);
          let creditAmt = getSelectedAssociatedCustomerCreditAmt();
          if(creditAmt != data.creditBalance){
            localStorage.setItem(
              "selectedCustomerCreditAmt",
              data.creditBalance
            );
          }
        }
        else {
          localStorage.removeItem("selectedCustomerID");
          localStorage.removeItem("selectedCustomerType");
          localStorage.removeItem("selectedCustomerSapId");
          localStorage.removeItem("selectedCustomerCreditAmt");
          localStorage.removeItem("persist:root");
          localStorage.clear();
          if (headers.AssociatedCustomer) {
            delete headers.AssociatedCustomer;
          }
          dispatch(fetchLogout(token));
          window.location.reload();
        };
      })
    }).catch(err => console.log(err));
  }, [children, token]);

  if (isValidToken) {
    return <div>{children}</div>;
  } else {
    return <Redirect to="/home" />;
  }
};

Authentication.propTypes = {};

export default Authentication;
