import { getMethod } from "../../utils/api";
import { getMyAccountUrl } from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const myAccountActionTypes = {
  FETCH_MY_ACCOUNT_DATA_SUCCESS: "FETCH_MY_ACCOUNT_DATA_SUCCESS",
  FETCH_MY_ACCOUNT_DATA_FAILTURE: "FETCH_MY_ACCOUNT_DATA_FAILURE",
};

const fetchMyAccountSuccessAction = (data) => {
  return {
    type: myAccountActionTypes.FETCH_MY_ACCOUNT_DATA_SUCCESS,
    data,
  };
};

// TO GET CURRENT LOGGED-IN USER BILL,SHIP & SOLD TO ADDRESS
export const fetchMyAccountDetailsAction =
  (isAuth, token) => async (dispatch) => {
    dispatch(loadingStart());
    let data = await getMethod(getMyAccountUrl)
      .then((data) => {
        dispatch(fetchMyAccountSuccessAction(data));
        return data;
      })
      .catch((err) => console.log(err));
    dispatch(loadingEnd());
    return data;
  };
