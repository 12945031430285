import {
  deleteMethod,
  getMethod,
  postMethod,
  putMethod,
} from "../../utils/api";
import {
  addReturnOrderImagesAPIUrl,
  cancelOrderAPIUrl,
  createReturnOrderAPIUrl,
  deleteOrderAPIUrl,
  downloadPDFReturnOrderAPIUrl,
  getEnv,
  getOrderDetailsAPIUrl,
  getOrderHistoryAPIUrl,
  orderInfoAPIUrl,
  returnMaterialReasonCodeAPIUrl,
} from "./../../utils/urls";
import { loadingEnd, loadingStart } from "./root.actions";

export const orderHistoryActionTypes = {
  FETCH_ORDER_HISTORY_DATA_SUCCESS: "FETCH_ORDER_HISTORY_DATA_SUCCESS",
  FETCH_ORDER_HISTORY_DATA_FAILTURE: "FETCH_ORDER_HISTORY_DATA_FAILURE",
  FETCH_SELECTED_ORDER_DATA_SUCCESS: "FETCH_SELECTED_ORDER_DATA_SUCCESS",
  FETCH_SELECTED_ORDER_DATA_FAILTURE: "FETCH_SELECTED_ORDER_DATA_FAILTURE",
  CANCEL_ORDER_DATA_SUCCESS: "CANCEL_ORDER_DATA_SUCCESS",
  CANCEL_ORDER_DATA_FAILURE: "CANCEL_ORDER_DATA_FAILURE",
  REMOVE_PRODUCT_DATA_SUCCESS: "REMOVE_PRODUCT_DATA_SUCCESS",
  UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS: "UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS",
  UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS:
    "UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS",
  UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS:
    "UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS",
};

export const orderHistoryClassTypes = {
  ORDER_CLASS_TYPE_CONFIRMED: "Confirmed",
  ORDER_CLASS_TYPE_COMPLETED: "Completed",
  ORDER_CLASS_TYPE_CANCELLED: "Order Cancelled",
  ORDER_CLASS_TYPE_BEINGPROCESSED: "Order is being processed",
  ORDER_CLASS_TYPE_ACKNOWLEDGED: "Order Acknowledged",
  ORDER_CLASS_TYPE_HASPROCESSED: "Order has been proceeded",
  ORDER_CLASS_TYPE_ISPROCESSING: "Order is processing",
  ORDER_CLASS_TYPE_CLAIMPROCESSING: "Claim processing",
  ORDER_CLASS_TYPE_CLAIMPENDING: "Claim pending",
  ORDER_CLASS_TYPE_CLAIMREJECTED: "Claim rejected",
  ORDER_CLASS_TYPE_CLAIMAPPROVED: "Claim approved",
};

const fetchOrderHistoryDataSuccessAction = (data) => {
  return {
    type: orderHistoryActionTypes.FETCH_ORDER_HISTORY_DATA_SUCCESS,
    data,
  };
};

const fetchSelectedOrderDataSuccessAction = (data) => {
  return {
    type: orderHistoryActionTypes.FETCH_SELECTED_ORDER_DATA_SUCCESS,
    data,
  };
};

const cancelOrderDataSuccessAction = (orderId) => {
  return {
    type: orderHistoryActionTypes.CANCEL_ORDER_DATA_SUCCESS,
    orderId,
  };
};

const fetchRemoveProductDataSuccessAction = (productId) => {
  return {
    type: orderHistoryActionTypes.REMOVE_PRODUCT_DATA_SUCCESS,
    productId,
  };
};

const fetchUpdateQunatityDataSuccessAction = (productInfo, productQuantity) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_QUNATITY_DATA_SUCCESS,
    productInfo,
    productQuantity,
  };
};

const fetchUpdateReturnReasonSuccessAction = (
  productInfo,
  productReasonCode
) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_REASONCODE_DATA_SUCCESS,
    productInfo,
    productReasonCode,
  };
};

const fetchUpdateImageUploadSuccessAction = (productId, payload) => {
  return {
    type: orderHistoryActionTypes.UPDATE_PRODUCT_IMAGEUPLOAD_DATA_SUCCESS,
    productId,
    payload,
  };
};
// TODO: need to remove loyaltyEnabled condition once single endpoint instance will create.
export const fetchOrderHistoryAction = (isAuth, token, payload) => async (
  dispatch
) => {
  dispatch(loadingStart());
  const url = !getEnv().loyaltyEnabled
    ? `${getOrderHistoryAPIUrl}`
    : getOrderHistoryAPIUrl;
  return await getMethod(getOrderHistoryAPIUrl)
    .then((data) => {
      dispatch(fetchOrderHistoryDataSuccessAction(data));
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};

export const fetchSelectedOrderHistoryAction = (
  isAuth,
  token,
  orderId
) => async (dispatch) => {
  dispatch(loadingStart());
  return await getMethod(getOrderDetailsAPIUrl + "/" + orderId)
    .then((data) => {
      dispatch(fetchSelectedOrderDataSuccessAction(data));
      dispatch(loadingEnd());
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};

export const cancelOrderDataAction = (isAuth, token, orderId) => async (
  dispatch,
  getState
) => {
  dispatch(loadingStart());
  return await putMethod(cancelOrderAPIUrl + "/" + orderId)
    .then((data) => {
      dispatch(loadingEnd());
      dispatch(cancelOrderDataSuccessAction(orderId));
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};

export const deleteOrderDataAction = (isAuth, token, orderId) => async (
  dispatch
) => {
  dispatch(loadingStart());
  return await getMethod(deleteOrderAPIUrl + "/" + orderId)
    .then((data) => {
      dispatch(cancelOrderDataSuccessAction(orderId));
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};

export const orderInfoDataAction = (isAuth, token, orderId) => async (
  dispatch
) => {
  dispatch(loadingStart());
  return await getMethod(orderInfoAPIUrl + "?orderId=" + orderId)
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};

export const returnMaterialReasonCodeDataAction = (token) => async (
  dispatch
) => {
  dispatch(loadingStart());
  return await getMethod(returnMaterialReasonCodeAPIUrl)
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};

export const deleteReturnItemsDataAction = (productId) => (dispatch) => {
  dispatch(loadingStart());
  dispatch(fetchRemoveProductDataSuccessAction(productId));
  dispatch(loadingEnd());
};

export const updateReturnQunatityDataAction = (
  productInfo,
  productQuantity
) => (dispatch) => {
  if (productQuantity >= 1) {
    dispatch(loadingStart());
    dispatch(
      fetchUpdateQunatityDataSuccessAction(productInfo, productQuantity)
    );
    dispatch(loadingEnd());
  }
};

export const updateReturnReasonCodeDataAction = (
  productInfo,
  productReasonCode
) => (dispatch) => {
  dispatch(loadingStart());
  dispatch(
    fetchUpdateReturnReasonSuccessAction(productInfo, productReasonCode)
  );
  dispatch(loadingEnd());
};

// RMA: Image Upload action
export const updateImageUploadCodeDataAction = (
  productId,
  payload,
  isAuth,
  token
) => async (dispatch) => {
  dispatch(loadingStart());
  await postMethod(addReturnOrderImagesAPIUrl, payload)
    .then((data) => {
      console.log("data ==>", data);
      dispatch(fetchUpdateImageUploadSuccessAction(productId, payload));
    })
    .catch((err) => console.log(err));
  dispatch(loadingEnd());
};

export const createReturnOrderDataAction = (token, payload) => async (
  dispatch
) => {
  dispatch(loadingStart());
  return await postMethod(createReturnOrderAPIUrl, payload)
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};

// FETCH INVOICE PDF FOR RETURN (NO NEED TO STORE IN REDUX)
export const fetchDownloadPDFAction = (isAuth, token, urlLink) => async (
  dispatch
) => {
  dispatch(loadingStart());
  return await getMethod(downloadPDFReturnOrderAPIUrl + "/" + urlLink)
    .then((data) => {
      dispatch(loadingEnd());
      return data;
    })
    .catch((err) => {
      console.log(err);
      dispatch(loadingEnd());
    });
};
